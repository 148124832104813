import React from 'react'

import Layout from 'layouts/index'
import Fade from 'react-reveal/Fade'

import TitleBox from 'components/TitleBox'
import {
  PageContainer,
  ContentWrapper,
  ContentText,
} from 'pageStyles/sobre-nos.styles'

import lab from 'images/laboratorio_1.jpg'
import provita from 'images/provita_logo.svg'

const Laboratorio = () => {
  return (
    <Layout>
      <>
        <TitleBox title='Laboratório' bg={lab} />
        <PageContainer>
          <Fade>
            <ContentWrapper>
              <ContentText>
                <p>
                  A Clínica Veterinária Derosso possui parceria com o
                  Laboratório Veterinário Próvita, especializado em patologia
                  clínica e tem como responsável técnica a médica veterinária
                  Drª Ana Laura D’Amico Fam.
                </p>
                <p>
                  Seus equipamentos são específicos de uso veterinários e com
                  constante controle de qualidade, garantindo maior
                  confiabilidade dos resultados respeitando as diferenças entre
                  animais e humanos.
                </p>
                <p>
                  Com estes exames, é possível o médico veterinário detectar
                  algumas alterações precocemente aumentando muito as chances de
                  bons resultados terapêuticos. Os exames laboratoriais, também
                  são de extrema importância como pré-operatórios, garantindo
                  segurança no procedimento anestésico e cirúrgico do paciente.
                </p>
                <p>
                  O Laboratório Veterinário Pró Vita oferece exames das
                  seguintes áreas:
                </p>
                <ul>
                  <li>Hematologia</li>
                  <li>Bioquímica e Enzimologia</li>
                  <li>Análise de Urina</li>
                  <li>Citologia</li>
                  <li>Imunologia</li>
                  <li>Parasitologia</li>
                </ul>
                <p>
                  Portanto, a agilidade dos exames é essencial para o sucesso do
                  tratamento, e aqui na Clínica Veterinária Derosso você
                  encontra um laboratório que pode liberar resultados em alguns
                  minutos.
                </p>
                <p>Tudo com muito carinho e respeito ao nosso paciente!!</p>
                <img src={provita} alt='provita_logo' />
              </ContentText>
            </ContentWrapper>
          </Fade>
        </PageContainer>
      </>
    </Layout>
  )
}

export default Laboratorio
